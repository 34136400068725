import { all } from 'redux-saga/effects';

import servico from './servico/sagas';
import gerente from './gerente/sagas';
import estabelecimento from './estabelecimento/sagas';


export default function* rootSaga() {
  return yield all([servico, gerente, estabelecimento]);
}
