import types from './types';

export function updateGerente(gerente) {
  return { type: types.UPDATE_GERENTE, gerente };
}

export function getGerente() {
  return { type: types.GET_GERENTE};
}
export function login() {
  return { type: types.LOGIN_GERENTE };
}
export function sair(){
  return { type: types.SAIR_GERENTE }
}