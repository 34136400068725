import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Drawer,
  Button,
  Notification,
} from 'rsuite';

import {
  updateServico,
  saveServico,
} from '../../store/modules/servico/actions';

import util from '../../services/util';

const ServicoDrawer = ({combo}) =>{
  const { servico, form, components } = useSelector(
    (state) => state.servico
  )
  const { estabelecimento } = useSelector(
    state => state.estabelecimento
  )
  const dispatch = useDispatch();

  const setServico = (key, value) => {
    dispatch(
      updateServico({
        servico: { ...servico, [key]: value },
      })
    );
  };

  const setComponents = (component, state) => {
    dispatch(
      updateServico({
        components: { ...components, [component]: state },
      })
    );
  };
  const save = () => {
    if(servico.categoria !== 'Odonto' && !servico.preco){
      Notification.error({
        placement: 'topStart',
        title: 'Calma lá!',
        description: 'Nessa categoria você também precisa preencher o Preço!',
      });
      return false;
    }
    if(servico.categoria === 'Odonto'){
      setServico('preco', null)
    }
    if(combo){
      setServico('categoria', "Combo")
    }

    dispatch(saveServico());
    setComponents('editar', false);
  };
  return(
    
    <Drawer
        show={components.editar}
        size="sm"
        onHide={() => setComponents('editar', false)}
    >
      <Drawer.Body>
        <h3>Atualizar serviço</h3>
        <div className="row mt-3">
          <div className="form-group col-12">
            <b className="servico-label">Título do {combo? "combo" : "servico"} *</b>
            <input
              type="text"
              className="form-control"
              placeholder="Titulo do serviço"
              value={servico.titulo}
              onChange={(e) => {
                setServico('titulo', e.target.value);
              }}
            />
          </div>
          {
            servico.categoria !== 'Odonto' && (
              <div className="form-group col-4">
                <b className="servico-label">Preço atual *</b>
                <input
                  
                  className="form-control"
                  placeholder="Preço do serviço"
                  value={servico.preco}
                  onChange={(e) => setServico('preco', e.target.value)}
                />
              </div>
            )
          }
          <div className={`form-group ${servico.categoria === 'Odonto'? 'col-6' : 'col-4.5'}`}>
            <b className="servico-label">Preço promocional</b>
            <input
              className="form-control"
              placeholder="1º vez do cliente"
              value={servico.preco1vez}
              onChange={(e) => setServico('preco1vez', e.target.value)}
            />
          </div>
         
          <div className="form-group col-3">
            <b className="servico-label">Status do anúncio</b>
            <select
              className="form-control"
              value={servico.status}
              onChange={(e) => setServico('status', e.target.value)}
            >
              <option value="A">Ativo</option>
              <option value="I">Inativo</option>
            </select>
          </div>
          <div className="form-group col-12">
            <b className="servico-label">Responsável pela criação do anúncio *</b>
            <input
              type="text"
              className="form-control"
              placeholder="Responsável pelo serviço"
              value={servico.responsavel}
              onChange={(e) => {
                setServico('responsavel', e.target.value);
              }}
            />
          </div>
          <div className="form-group col-5">
            <b className="servico-label">Conselho</b>
            <select
              className="form-control"
              value={servico?.responsavelConselho}
              onChange={(e) => setServico('responsavelConselho', e.target.value)}
            >
              <option value="">escolher conselho</option>
              <option value="CRM">CRM</option>
              <option value="CRO">CRO</option>
              <option value="CRP">CRP</option>
              <option value="CRBM">CRBM</option>
              <option value="COREM">COREM</option>
              <option value="CREFITO">CREFITO</option>
              <option value="CRMV">CRMV</option>
            </select>
          </div>
          <div className="form-group col-4 pl-0">
            <b className="servico-label">UF</b>
            <select
              className="form-control"
              value={servico?.responsavelUf}
              onChange={(e) => setServico('responsavelUf', e.target.value)}
            >
              <option value="">escolher UF</option>
              <option value="PA">PA</option>
              <option value="PA">PA</option>
              <option value="MA">MA</option>
              <option value="RJ">RJ</option>
              <option value="todos">todos</option>
            </select>
          </div>
          <div className="form-group col-2 pl-0">
            <b className="servico-label">numeração</b>
            <input
              className="form-control"
              placeholder="numeração"
              value={servico.responsavelNumeracao}
              type="number"
              onChange={(e) => setServico('responsavelNumeracao', e.target.value)}
            />
          </div>
          <div className="form-group col-12">
            <b className="servico-label">Descrição *</b>
            <textarea
              rows="5"
              className="form-control"
              placeholder="Descrição do serviço..."
              value={servico.descricao}
              onChange={(e) => setServico('descricao', e.target.value)}
            ></textarea>
          </div>
          {
            !combo && (
              <div className="form-group col-12">
                <b className="servico-label">Categoria do serviço *</b>
                <select
                  className="form-control"
                  value={combo? "Combo" : servico.categoria}
                  onChange={(e) => setServico('categoria', e.target.value)}
                >
                  {
                    estabelecimento?.categorias?.map((e) =>(
                      <option value={e}>
                        {e}
                      </option>
                    ))
                  }
                  {
                    !estabelecimento?.categorias?.length && (
                      <option value={false}>
                        você não tem categorias cadastradas
                      </option>
                    )
                  }
                </select>
              </div>
            )
          }
          <div className="form-group col-12">
            <b className="d-block servico-label">Imagem do {combo? "combo" : "serviço"} PNG, JPEG E JPG (Imagem quadrada) <i className="fas fa-question-circle fa-sm" data-toggle="tooltip" data-placement="top" title="Adicionar imagem aumenta o engajamento." /></b>
            <p className="d-block servico-label" style={{ fontSize: 'smaller' }}>Imagens grandes podem não aparecer aqui. Não se preocupe, clique em SALVAR para concluir.</p>
            <input 
              type="file"
              id="file"
              name="file"
              style={{
                width: 80,
                height: 80,
                border: 'none',
                cursor:  'pointer',
                margin:  0,
                position: 'relative',
                zIndex: 2,
                opacity: 0,
              }}
              accept=".jpg, .jpeg, .png"
              onChange={e => e.target.files[0] && setServico('arquivos', [e.target.files[0]])}
            />
            <img
              alt="..."
              style={{
                width: 80,
                height: 80,
                background:  '#FFF',
                border: 'none',
                position: 'absolute',
                left: 15,
                zIndex: 1,
                borderRadius: 5
              }}
              src={!!servico?.arquivos[0]?.name ? URL.createObjectURL(servico.arquivos[0]) : servico?.imagem ? `${util.AWS.bucketURL}/${servico?.imagem}` :  'https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg'}
            />
          </div>
        </div>
        <Button
          loading={form.saving}
          color='primary'
          size="lg"
          block
          onClick={() => save()}
          className="mt-3"
        >
          Salvar {combo? "Combo" : "Serviço"}
        </Button>
      </Drawer.Body>
    </Drawer>
  )
}

export default ServicoDrawer;