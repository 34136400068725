import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import types from './types';

import api from '../../../services/api';
import { notification } from '../../../services/rsuite';
import {  login, updateGerente } from './actions';

export function* getGerente(){
  const { form } = yield select((state) => state.servico);
  const { gerente } = yield select((state) => state.gerente);
  try {
    const { data: res } = yield call(
      api.get,
      `/gerente/${gerente?.usuario}`
    );
    if (res.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: res.message,
      });
      return false;
    }
    yield put(updateGerente({ gerente: res.gerente, form: { ...form, categorias: res?.gerente?.categorias}}));
    localStorage.setItem('@auth:gerente', JSON.stringify(res.gerente));
  } catch (err) {
    notification('error', {
      placement: 'topStart',
      title: 'Ops...',
      description: err.message,
    });
  }
}
export function* logIn() {
  const { form, gerente } = yield select((state) => state.gerente);
  yield put(updateGerente({...gerente, form: { ...form, saving: true } }));
  try {
    const { data: res } = yield call(api.post, '/auth/login', form);
    if(res.error){
      yield put(updateGerente({...gerente, form: { ...form, saving: false } }));
      notification('error', { placement: 'topStart',title: 'Ops...', description: res.message.toString() || res.toString()});
      return false;
    }
    localStorage.setItem('@auth:gerente', JSON.stringify(res?.gerente?._doc));
    localStorage.setItem('@auth:accessToken', JSON.stringify(res?.gerente?.accessToken));
    yield put(updateGerente({gerente: res.gerente?._doc, accessToken: res?.gerente?.accessToken, form: { ...form, saving: false }}))
    window.location.reload();
    return false;
  }catch(err){
    yield put(updateGerente({...gerente, form: { ...form, saving: false } }));
    notification('error', {
      placement: 'topStart',
      title: 'Ops...',
      description: err.message || err
    });
  }
}
export function* sair() {
  const { form } = yield select((state) => state.gerente);
  try{
    localStorage.removeItem("@auth:gerente");
    localStorage.removeItem("@auth:accessToken");
    yield put(updateGerente({gerente: {}, accessToken: '', form: { ...form, saving: false }}))

  }catch(err){
    notification('error', { placement: 'topStart',title: 'Ops', description: err});
  }
}


export default all([
  takeLatest(types.GET_GERENTE, getGerente),
  takeLatest(types.LOGIN_GERENTE, logIn),
  takeLatest(types.SAIR_GERENTE, sair),
]);