import axios from 'axios';
import util from './util';

const api = axios.create({
  baseURL: util.baseURL,
  withCredentials: false,
  headers: {
    token: localStorage.getItem("@auth:accessToken"),
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS', 
  },
});

export default api;