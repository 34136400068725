import { useLocation, Navigate, useParams } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
} from "reactstrap";
import Header from '../../components/Header';

import Helmet from 'react-helmet'
import Footer from '../../components/Footer';

const AuthLayout = ({children}) =>{
  const location = useLocation();
  const { id, token }  = useParams();
  return(
    <div>
      <Helmet title={`CRM Dr. Clube${
          location.pathname === '/login' && ' - Fazer Login'
        }`}
      />
      <div className="row">
        <Header signed={false}/>
          <div className="main-content w-100">
            {
              location.pathname != '/' && (
                <div className="bradcam_area bradcam_bg_1">
                  <div className="container">
                      <div className="row">
                          <div className="col-xl-12">
                              <div className="bradcam_text">
                                  <h3>
                                  { 
                                    location.pathname === '/login' && 'Fazer Login'
                                  }
                                  </h3>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              )
            }
          </div>
      </div>
      {children}
    </div>
  )
}
export default AuthLayout;