import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import types from './types';

import api from '../../../services/api';
import { notification } from '../../../services/rsuite';
import { updateEstabelecimento, login } from './actions';

export function* allEstabelecimentos() {
  const { form, estabelecimento } = yield select((state) => state.estabelecimento);
  try {
    yield put(updateEstabelecimento({ form: { ...form, filtering: true } }));

    const { data: res } = yield call(
      api.get,
      `/estabelecimento/lista`
    );
    yield put(updateEstabelecimento({ form: { ...form, filtering: false } }));

    if (res.error) {
      // ALERT DO RSUITE
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: res.message,
      });
      return false;
    }


    yield put(updateEstabelecimento({...estabelecimento, estabelecimentos: res.estabelecimentos }));
  } catch (err) {
    // COLOCAR AQUI O ALERT DO RSUITE
    yield put(updateEstabelecimento({ form: { ...form, filtering: false } }));
    notification('error', {
      placement: 'topStart',
      title: 'Ops...',
      description: err.message,
    });
  }
}

export function* getEstabelecimento(){
  const { form } = yield select((state) => state.servico);
  const { estabelecimento } = yield select((state) => state.estabelecimento);

  try {
    const { data: res } = yield call(
      api.get,
      `/estabelecimento/${estabelecimento._id}`
    );
    if (res.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: res.message,
      });
      return false;
    }
    yield put(updateEstabelecimento({ estabelecimento: res.estabelecimento, form: { ...form, categorias: res?.estabelecimento?.categorias}}));
    localStorage.setItem('@auth:estabelecimento', JSON.stringify(res.estabelecimento));
  } catch (err) {
    notification('error', {
      placement: 'topStart',
      title: 'Ops...',
      description: err.message,
    });
  }
}
export function* editar(){
  const { form, estabelecimento } = yield select((state) => state.estabelecimento);
  yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: true } })); 
  try{
    const { data: res } = yield call(api.put, `/estabelecimento/${estabelecimento?._id}`, form);
    if(res.error){
      notification('error', { placement: 'topStart',title: 'Ops...', description: res.message});
      yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
      return false;
    }
    notification('success', { placement: 'topStart',title: 'Pronto', description: "Seus novos dados foram salvos!"});
    yield put(updateEstabelecimento({estabelecimento: res.estabelecimento , form: { ...form, saving: false } }));
    localStorage.setItem('@auth:estabelecimento', JSON.stringify(res.estabelecimento));
    return false;
  }catch(err){
    notification('error', { placement: 'topStart',title: 'Ops', description: err});
    yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
  }
}
export function* deletar(){
  const { form, estabelecimento } = yield select((state) => state.estabelecimento);
  yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: true } })); 
  try{
    const { data: res } = yield call(api.delete, `/estabelecimento/${estabelecimento?._id}`);
    if(res.error){
      notification('error', { placement: 'topStart',title: 'Ops...', description: res.message});
      yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
      return false;
    }
    notification('success', { placement: 'topStart',title: 'Pronto', description: res.message});
    yield put(updateEstabelecimento({form: { ...form, saving: false } }));
    
    return false;
  }catch(err){
    notification('error', { placement: 'topStart',title: 'Ops', description: err.message || err});
    yield put(updateEstabelecimento({...estabelecimento, form: { ...form, saving: false } }));
  }
}

export default all([
  takeLatest(types.GET_ESTABELECIMENTO, getEstabelecimento),
  takeLatest(types.ALL_ESTABELECIMENTO, allEstabelecimentos),
  takeLatest(types.EDITAR_ESTABELECIMENTO, editar),
  takeLatest(types.DELETAR_ESTABELECIMENTO, deletar)
]);