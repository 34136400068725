import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logodoutorbranca.png';
import { sair } from '../../store/modules/gerente/actions';
import { useDispatch } from 'react-redux';


const Sidebar = (props) => {
  const dispatch = useDispatch();
  const handleLogout = (e) =>{
    e.preventDefault();
    dispatch(sair())
  }
  const location = useLocation();
  return (
    <sidebar className="col-2 py-0">
      <img src={logo} className="img-fluid px-3 py-4" />
      <ul> 
        <li>
          <Link
            to="/estabelecimentos"
            className={
              location.pathname === '/estabelecimentos' ? 'active' : ''
            }
          >
            <span className="mdi mdi-briefcase" />
            <text>estabelecimentos</text>
          </Link>
        </li>
        <li>
          <Link
            to="/beneficio"
            className={
              location.pathname === '/beneficio' ? 'active' : ''
            }
          >
            <span className="mdi mdi-credit-card-outline" />
            <text>Dr. Clube benefício</text>
          </Link>
        </li>
        <li>
          <Link
            to="/enderecos"
            className={
              location.pathname === '/enderecos' ? 'active' : ''
            }
          >
            <span className="mdi mdi-map-marker" />
            <text>endereços</text>
          </Link>
        </li>
        <li>
          <Link
            to="/Convenios"
            className={
              location.pathname === '/Convenios' ? 'active' : ''
            }
          >
            <span className="mdi mdi-flag"></span>
            <text>Convênios</text>
          </Link>
        </li>
        <li>
          <Link
            to="/Cupons"
            className={
              location.pathname === '/Cupons' ? 'active' : ''
            }
          >
            <span className="mdi mdi-tag"></span>
            <text>Cupons</text>
          </Link>
        </li>
        <li>
          <Link
            to="/Banners"
            className={
              location.pathname === '/Banners' ? 'active' : ''
            }
          >
            <span className="mdi mdi-tag"></span>
            <text>Banners</text>
          </Link>
        </li>
        <li>
          <Link to="/" onClick={handleLogout}>
            <span className="mdi mdi-logout" />
            <text>Sair</text>
          </Link>
        </li>
        
      </ul>
    </sidebar>
  );
};

export default Sidebar;