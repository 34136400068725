import { 
    Input,
    Button,
    Spinner
} from 'reactstrap';
  
import {
    DatePicker,
    Drawer,
    Notification,
} from 'rsuite';
  
import { updateEstabelecimento, editarEstabelecimento } from '../../store/modules/estabelecimento/actions';
import { useDispatch, useSelector } from 'react-redux';
/*   
import './styles.css'
   */
const AdicionarConvenioDrawer = () =>{
    const { form, estabelecimento, components } = useSelector((state) => state.estabelecimento);
    const dispatch = useDispatch();

    const setComponents = (component, state) => {
      dispatch(
        updateEstabelecimento({
          components: { ...components, [component]: state },
        })
      );
    };
  
    const convenios = [
      {
        nome: "Unimed",
        logo: 'https://iili.io/HcVeWxa.jpg'
      },
      {
        nome: "Hapvida",
        logo: 'https://iili.io/HcVkM22.jpg'
      },
      {
        nome: "Amil",
        logo: 'https://iili.io/HcVkCXa.jpg'
      },
    ]

    const add = (value) => {
      const { convenios } = form;
      dispatch(
        updateEstabelecimento({
          form: { ...form, convenios: estabelecimento?.convenios? [...estabelecimento?.convenios, value] : [value]}
        })
      );
    };
    const onSubmit = async data =>{
      dispatch(
        editarEstabelecimento()
        )
       setComponents('adicionarConvenio', false)
    }
    return(
        <Drawer
            show={components?.adicionarConvenio}
            size="sm"
            onHide={() => setComponents('adicionarConvenio', false)}
        >
            <Drawer.Body>
            {
            convenios?.length !== estabelecimento?.convenios?.length ?(
                <>
                <div className="form-group col-12">
                    <b className="servico-label">Convênios</b>
                    <select
                    className="form-control"
                    value={form?.convenio}
                    onChange={(e) => add(JSON.parse(e.target.value || ''))}
                    >
                    <option>Escolha um convênio</option>
                    {
                        convenios
                        .filter(e => !estabelecimento?.convenios?.map(e => e?.nome).includes(e.nome))
                        .map(e => (
                        <option value={JSON.stringify(e)}>{e?.nome}</option>
                        ))
                    }
                    </select>
                </div>
                <div className="col-12">
                    {
                    form.saving? (
                        <Button className="col-md-12" color="primary">
                            <Spinner size="sm"/>
                        </Button>
                    ):
                    (
                        <Button 
                        className="col-md-12 m-2" 
                        color="primary"
                        type="button"
                        onClick={() => onSubmit()}
                        >
                        Adicionar Convênio
                        </Button>
                    )
                    }
                </div>
                </>
            ):
            (
                <div className="h-100 d-flex align-items-center justify-content-center">
                <strong>Você já adicionou todos os convênios disponíveis.</strong>
                </div>
            )
            }
            </Drawer.Body>
        </Drawer>
    )
  }
  export default AdicionarConvenioDrawer;