import { useNavigate, Link, useLocation } from 'react-router-dom';
import React from 'react';

import IsVisible from 'react-is-visible';
import Logodoutor from '../../assets/img/logodoutor.png'

function Footer() {
  return (
    <IsVisible>
       {(IsVisible) => (
    <footer className="footer py-0">
        <div className="footer_top">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-md-6 col-lg-4">
                        <div className="footer_widget">
                        <div className="logo">
                            <Link to="/">
                              <img 
                                src={Logodoutor}
                                style={{width: 150}}
                                alt="..."
                              />
                            </Link> 
                        </div>
                           
                            <div className="socail_links">
                                <ul>
                                    <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                        <a href="#">
                                            <i className="ti-facebook"></i>
                                        </a>
                                    </li>
                                    <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                        <a href="#">
                                            <i className="ti-twitter-alt"></i>
                                        </a>
                                    </li>
                                    <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                        <a href="#">
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-2 offset-xl-1 col-md-6 col-lg-3">
                        <div className="footer_widget">
                            <h3 className={`footer_title ${IsVisible &&  'animate__animated animate__fadeInUp'}`}> 
                                    Sobre
                            </h3>
                            <ul>
                                <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                    <a href="termo">Termo de uso</a></li>
                                <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                    <a href="politicaprivacidade">Política de privacidade</a></li>
                                <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                    <a href="politicacookies">Política de cookies</a></li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-xl-2 col-md-6 col-lg-2">
                        <div className="footer_widget">
                            <h3 className={`footer_title ${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                Links Úteis
                            </h3>
                            <ul>
                                <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                    <a href="#">Início</a></li>
                                <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                    <a href="#">Preço</a></li>
                                <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                    <a href="#">Contato</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 col-lg-3">
                        <div className="footer_widget">
                            <h3 className={`footer_title ${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                Aplicativo cliente
                            </h3>
                            <ul>
                                <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                    <a href="#">
                                        Aplicativo para iPhone (em breve)

                                    </a>
                                </li>
                                <li className={`${IsVisible &&  'animate__animated animate__fadeInUp'}`}>
                                    <a href="#">
                                    Aplicativo para Android
                                    </a>
                                </li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copy-right_text">
            <div className="container">
                <div className="footer_border"></div>
                <div className="row">
                    <div className="col-xl-12">
                        <p className="copy_right text-center">
                            Copyright &copy;<script>document.write(new Date().getFullYear());</script>
                             Todos os direitos reservados | Dr. Clube Descontos e Benefícios Internet Ltda | CNPJ: 29.208.531/0001-56 <br/>Feito com 
                             <i className="fa fa-heart" aria-hidden="true"></i> por <a target="_blank">Doutor Clube</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
       )}
       </IsVisible>
  )
}

export default Footer;