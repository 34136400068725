import { useState, useEffect } from 'react';
import { Button, Spinner} from 'reactstrap';
import {Drawer} from 'rsuite';


import { notification } from '../../services/rsuite';
import api from '../../services/api';

import TelefoneBrasileiroInput from 'react-telefone-brasileiro/dist/TelefoneBrasileiroInput';


import './styles.css'

  
const Cupons = () =>{
    const [form, setForm] = useState({});
    const [cupons, setCupons] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [search, setSearch] = useState('')

    const onSubmit = async () =>{
      setForm({...form, saving: true})
      const { data } = await api.post('/cupom', form);
      if (data.error) {
        notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data.message,
        });
      }
      setForm({})
    }

    const onRemove = async id =>{
      setForm({...form, saving: true})
      
      const { data } = await api.delete(`/cupom/${id}`);
      
      if (data.error) {
          notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data.message,
          });
          return false
      }
      notification('success', {
          placement: 'topStart',
          title: 'Pronto',
          description: data.message,
      });
      setForm({})
    }

    const onUpdate = async () =>{
      setForm({...form, saving: true})
      
      const { data } = await api.put(`/cupom/${form._id}`, {...form, _id: undefined, __v: undefined});

      
      if (data.error) {
          notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data.message?.name || data.message,
          });
          return false
      }
      notification('success', {
          placement: 'topStart',
          title: 'Pronto',
          description: data.message,
      });
      setForm({})
    }

    useEffect(() =>{
        const fetchcupons = async () =>{
            const { data } = await api.get(`/cupom/lista`);    
            if (data.error) {
            notification('error', {
                placement: 'topStart',
                title: 'Ops...',
                description: data.message,
            });
            return false;
            }                
            setCupons(data?.cupons);
        }            
        fetchcupons();
    }, [form?.saving]);

    
    useEffect(() =>{
        const fetchCitys = async () =>{
            const { data } = await api.get(`/cidade/todas`);    
            if (data.error) {
              notification('error', {
                placement: 'topStart',
                title: 'Ops...',
                description: data.message,
              });
              return false;
            }
            
            setCidades(data.cidades);
          }         
          fetchCitys();
    }, [])
    
  return(
    <>
    <Drawer
        show={form?.components?.create}
        size="sm"
        onHide={() => setForm({})}
    >
      <Drawer.Body>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Nome</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Nome do Cupom"
            value={form?.nome}
            onChange={(e) => {
              setForm({...form, nome: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Preço</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Desconto do cupom"
            value={form?.preco}
            onChange={(e) => {
              setForm({...form, preco: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Código do cupum</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Código do cupum"
            value={form?.codigo}
            onChange={(e) => {
              setForm({...form, codigo: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Logo(url)</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Url da logo"
            value={form?.logo}
            onChange={(e) => {
              setForm({...form, logo: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Logo home(url)</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Url da logo"
            value={form?.logoHome}
            onChange={(e) => {
              setForm({...form, logoHome: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Cidade</b>
          <select
            className="form-control-alternative form-control"
            value={form?.cidade}
            onChange={(e) => {
              setForm({...form, cidade: e.target.value});
            }}
        >
            <option value="">Escolher cidade</option>
            {
                cidades
                ?.map(e =>(
                    <option value={e?.nome} key={e?.nome}>{e?.nome}</option>
                ))
            }
        </select>
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Telefone</b>
          <TelefoneBrasileiroInput
            className="form-control form-control-alternative"
            id="input-telefoneResponsavel"
            placeholder="Telefone do responsável"
            type="text"
            temDDD
            separaDDD
            value={form?.telefone}
            onChange={(e) => {
              setForm({...form, telefone: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Primeira descrição</b>
          <textarea
            rows="5"
            className="form-control form-control-alternative"
            value={form?.descricao1}
            id="input-descricao1"
            placeholder="Primeira descrição"
            type="textarea"
            onChange={(e) => {
              setForm({...form, descricao1: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Segunda descrição</b>
          <textarea
            rows="5"
            className="form-control form-control-alternative"
            value={form?.descricao2}
            id="input-descricao2"
            placeholder="Segunda descricao"
            type="textarea"
            onChange={(e) => {
              setForm({...form, descricao2: e.target.value});
            }}
          />
        </div>
        <div className="col-12">
          {
            form.saving? (
                <Button className="col-md-12" color="primary">
                  <Spinner size="sm"/>
                </Button>
            ):
            (
              <Button 
                className="col-md-12 m-2" 
                color="primary"
                type="button"
                onClick={() => onSubmit()}
              >
                Adicionar Cupom
              </Button>
            )
          }
        </div>
      </Drawer.Body>
      </Drawer>
      <Drawer
        show={form?.components?.update}
        size="sm"
        onHide={() => setForm({})}
    >
      <Drawer.Body>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Nome</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Nome do Cupom"
            value={form?.nome}
            onChange={(e) => {
              setForm({...form, nome: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Código</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Código do cupom"
            value={form?.codigo}
            onChange={(e) => {
              setForm({...form, codigo: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Preço</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Desconto do cupom"
            value={form?.preco}
            onChange={(e) => {
              setForm({...form, preco: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Logo(url)</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Url da logo"
            value={form?.logo}
            onChange={(e) => {
              setForm({...form, logo: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Logo da home(url)</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Url da logo"
            value={form?.logoHome}
            onChange={(e) => {
              setForm({...form, logoHome: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Cidade</b>
          <select
            className="form-control-alternative form-control"
            value={form?.cidade}
            onChange={(e) => {
              setForm({...form, cidade: e.target.value});
            }}
        >
            <option value="">Escolher cidade</option>
            {
                cidades
                ?.map(e =>(
                    <option value={e?.nome} key={e?.nome}>{e?.nome}</option>
                ))
            }
        </select>
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Telefone</b>
          <TelefoneBrasileiroInput
            className="form-control form-control-alternative"
            id="input-telefoneResponsavel"
            placeholder="Telefone do responsável"
            type="text"
            temDDD
            separaDDD
            value={form?.telefone}
            onChange={(e) => {
              setForm({...form, telefone: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Primeira descrição</b>
          <textarea
            rows="5"
            className="form-control form-control-alternative"
            value={form?.descricao1}
            id="input-descricao1"
            placeholder="Primeira descrição"
            type="textarea"
            onChange={(e) => {
              setForm({...form, descricao1: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Segunda descrição</b>
          <textarea
            rows="5"
            className="form-control form-control-alternative"
            value={form?.descricao2}
            id="input-descricao2"
            placeholder="Segunda descricao"
            type="textarea"
            onChange={(e) => {
              setForm({...form, descricao2: e.target.value});
            }}
          />
        </div>
        <div className="col-12">
          {
            form.saving? (
                <Button className="col-md-12" color="primary">
                  <Spinner size="sm"/>
                </Button>
            ):
            (
              <Button 
                className="col-md-12 m-2" 
                color="primary"
                type="button"
                onClick={() => onUpdate()}
              >
                Atualizar Cupom
              </Button>
            )
          }
        </div>
      </Drawer.Body>
      </Drawer>
      <div className="col p-5 overflow-inherit cupons" style={{minHeight: '100vh'}}>
        <div className="d-sm-flex align-items-center mb-4">
          <h2 className="mb-4 mt-4 text-muted mr-auto">Cupons</h2>
          <div className="form-group col-3">
            <label
              className="form-control-label mb-1 ml-1"
              htmlFor="input-status"
            >
              Pesquisar
            </label>
            <div className="input-group input-group-alternative">
              <input
                className="form-control form-control-alternative shadow"
                value={search}
                onChange={e => setSearch(e.target.value)}
                id="input-status"
                placeholder="Digite um nome, preço, cidade ou telefone"
              />
              <div className="input-group-append">
                <span className="input-group-text bg-transparent">
                  <i className="fa fa-search"></i>
                </span>
              </div>
            </div>
          </div>
          <div>
            <Button 
              className="" 
              color="primary" 
              type="button"
              onClick={() => setForm({components: {create: true}})}
            >
              Novo Cupom
            </Button>
          </div>
        </div>
      <div className="">
      { 
        cupons
        ?.filter(e => e?.nome?.includes(search) || e?.telefone?.includes(search) || e?.cidade?.includes(search) || e?.preco?.includes(search))
        ?.map(e => (
          <div className="row col-12 mt-1 border-bottom border-black item" key={e?.nome}>
            <div className="column">
              <img 
                src={e.logo}
                className=""
                />
            </div>
            <div className="column">
              <h5 className="ml-2" style={{minWidth: 150}}>{e?.nome} - {e?.preco}</h5>
            </div>
            {
              form.saving? (
              <Button 
                color="info" 
                type="button"
                className="ml-3"
              >
                <Spinner size="sm"/>
              </Button>
            ):(
              <Button 
                color="info" 
                type="button"
                className="ml-3"
                onClick={() => setForm({...e, components: {update: true}})}
              >
                Editar
              </Button>
            )
          }
          {
            form.saving? (
            <Button 
              color="danger" 
              type="button"
            >
              <Spinner size="sm"/>
            </Button>
          ):(
            <Button 
              color="danger" 
              type="button"
              onClick={() => onRemove(e?._id)}
            >
              Excluir
            </Button>
          )
        }
        </div>
      ))
    }
    </div>
  </div>
  </>
  )
}
export default Cupons;