import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DatePicker,
  Drawer,
  Uploader,
  Icon,
  Notification,
  Message,
} from 'rsuite';

import {
  Button,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from 'react-input-mask';


import api from '../../services/api';


import { updateEstabelecimento, editarEstabelecimento } from '../../store/modules/estabelecimento/actions';


const EstabelecimentoDrawer = () =>{
  const { estabelecimento, components, form } = useSelector(
    state => state.estabelecimento
  )
  const [opcoes, setOpcoes] = useState({cidades: [], estados: []});

  const dispatch = useDispatch();

  const setEstabelecimento = (key, value) => {
    dispatch(
      updateEstabelecimento({
        form: {...form, [key]: value }
      })
    );
  };
  const setEndereco = (key, value) => {
    dispatch(
      updateEstabelecimento({
        form: {...form, endereco: {...estabelecimento.endereco, ...form.endereco, [key]: value } }
      })
    );
  };

  const setComponents = (component, state) => {
    dispatch(
      updateEstabelecimento({
        components: { ...components, [component]: state }
      })
    );
  };
  const onSubmit = (e) =>{
    e.preventDefault()
    dispatch(
      editarEstabelecimento()
    )
    setComponents('editar', false)
  }
  
  useEffect(() => {
    const fetchOpcoes = async () =>{
      const { data: est } = await api.get(`/estado/lista`);
      if(est.error){
        return false;
      }
      const { data: cid } = await api.get(`/cidade/todas`);
      if(cid.error){
        return false;
      }
      setOpcoes({cidades: cid.cidades, estados: est.estados });
    }
    fetchOpcoes();
  }, [])
  
  return(
    <Drawer
        show={components?.editar}
        size="sm"
        onHide={() => setComponents('editar', false)}
    >
      <Drawer.Body>
        <Form onSubmit={form.saving? (e) => e.preventDefault() : (e) => onSubmit(e)}>
        <h6 className="heading-small text-muted mb-4">
          Informações da Conta
        </h6>
                  <div>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nomeResponsável"
                          >
                            Nome completo do responsável da conta *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nomeResponsável"
                            placeholder="Nome do responsável"
                            type="text"
                            defaultValue={estabelecimento?.nomeResponsavel}
                            onChange={e =>  setEstabelecimento('nomeResponsavel', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-telefoneResponsavel"
                          >
                            Telefone do responsável *
                          </label>
                          <TelefoneBrasileiroInput
                            className="form-control form-control-alternative"
                            id="input-telefoneResponsavel"
                            placeholder="Telefone do responsável"
                            type="text"
                            temDDD
                            separaDDD
                            value={form?.telefoneResponsavel || estabelecimento?.telefoneResponsavel}
                            onChange={e =>  setEstabelecimento('telefoneResponsavel', e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cpfResponsavel"
                          >
                            Cpf do responsável *
                          </label>
                          <CpfCnpj
                            className="form-control form-control-alternative"
                            id="input-cpfResponsavel"
                            placeholder="Cpf do responsável"
                            value={form?.cpfResponsavel || estabelecimento?.cpfResponsavel}
                            type="text"
                            onChange={e =>  setEstabelecimento('cpfResponsavel', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-site"
                          >
                            Email da conta *
                          </label>
                          <Input
                            className="form-control form-control-alternative"
                            defaultValue={estabelecimento?.email}
                            id=""
                            placeholder="Email"
                            type="text"
                            temDDD
                            separaDDD
                            onChange={(e) => setEstabelecimento('email', e.target.value?.trim())}
                          />
                        </FormGroup>
                      </Col>
                  </Row>
                </div>
                <br/>
                <h6 className="heading-small text-muted mb-4">
                informações do estabelecimento
                </h6>
                <div>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Nome Empresárial *
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={estabelecimento?.nomeEmpresarial}
                          id="input-nomeEmpresarial"
                          placeholder="Nome Empresárial"
                          type="name"
                          onChange={(e) => setEstabelecimento('nomeEmpresarial', e.target.value?.trim())}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-nomeFantasia"
                        >
                          Nome Fantasia *
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={estabelecimento?.nomeFantasia}
                          onChange={(e) => setEstabelecimento('nomeFantasia', e.target.value.trim())}
                          id="input-nomeFantasia"
                          placeholder="Nome fantasia"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-cnpj"
                        >
                          Cnpj *
                        </label>
                        <CpfCnpj
                          className="form-control form-control-alternative"
                          id="input-cnpj"
                          placeholder="cnpj"
                          value={form?.cnpj || estabelecimento?.cnpj}
                          type="text"
                          onChange={e =>  setEstabelecimento('cnpj', e.target.value.trim())}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-descricao"
                      >
                        Faça uma breve descrição da empresa *
                      </label>
                      <Input
                        rows="5"
                        className="form-control-alternative"
                        defaultValue={estabelecimento?.descricao}
                        value={form?.decricao}
                        id="input-descricao"
                        placeholder="descricao"
                        type="textarea"
                        onChange={e => setEstabelecimento('descricao', e.target.value.trim())}
                      />
                    </FormGroup>
                  </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Forma de agendamento
                </h6>
                <div>
                 
                </div>
              
                <div>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Telefone *
                        </label>
                        <TelefoneBrasileiroInput
                          value={form?.telefone || estabelecimento?.telefone}
                          placeholder="telefone"
                          temDDD
                          separaDDD
                          className="form-control form-control-alternative"
                          onChange={e =>  setEstabelecimento('telefone', e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Whatsapp *
                        </label>
                        <TelefoneBrasileiroInput
                          value={form?.whatsapp || estabelecimento?.whatsapp}
                          placeholder="whatsapp"
                          temDDD
                          separaDDD
                          className="form-control form-control-alternative"
                          onChange={e => setEstabelecimento('whatsapp', e.target.value.trim())}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <h6 className="heading-small text-muted mb-4">
                  Localização
                  </h6>
                  <div>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Endereço *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.endereco?.endereco}
                            id="input-address"
                            placeholder="Endereço"
                            type="text"
                            onChange={e => setEndereco('endereco', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-complemento"
                          >
                            Complemento
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.endereco?.complemento}
                            id="input-complemento"
                            placeholder="Complemento"
                            type="text"
                            onChange={e => setEndereco('complemento', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cep"
                          >
                            Cep *
                          </label>
                          <InputMask
                            placeholder="Cep"
                            className="form-control form-control-alternative"
                            mask="99999-999"
                            type="text"
                            autoComplete="new-p"
                            onChange={(e) => setEndereco('cep', e.target.value)}
                            /* onBlur={() => getCep()} */
                            value={form?.endereco?.cep || estabelecimento?.endereco?.cep  || ""}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-district"
                          >
                            Bairro *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.endereco?.bairro}
                            id="input-district"
                            placeholder="Bairro"
                            type="text"
                            onChange={e => setEndereco('bairro', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Nùmero *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.endereco?.numero}
                            id="input-numero"
                            placeholder="Número"
                            type="number"
                            onChange={(e) => setEndereco('numero', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
            
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Cidade *
                          </label>
                          <select
                            className="form-control-alternative form-control"
                            value={form?.endereco?.cidade || estabelecimento?.endereco?.cidade || ""}
                            onChange={e => setEndereco('cidade', e.target.value)}
                          >
                            <option value="">Escolher cidade</option>
                            {
                              opcoes.cidades
                              ?.filter(e => e.estado === form?.endereco?.estado || e.estado === estabelecimento?.endereco?.estado)
                              ?.map(e =>(
                                <option value={e?.nome} key={e?.nome}>{e?.nome}</option>
                              ))
                            }
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-estado"
                          >
                            Estado *
                          </label>
                          <select
                            className="form-control-alternative form-control"
                            value={form?.endereco?.estado || estabelecimento?.endereco?.estado || ""}
                            onChange={e => setEndereco('estado', e.target.value.trim())}
                          >
                          <option value="">Escolher estado</option>
                            {
                              opcoes.estados
                              ?.map(e =>(
                                <option value={e?.sigla} key={e?.sigla}>{e?.sigla}</option>
                              ))
                            }
                        </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-district"
                          >
                            Código do estabelecimento
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={estabelecimento?.codigoBeneficio}
                            maxLength={6}
                            id="input-district"
                            placeholder="Bairro"
                            type="text"
                            onChange={e => setEstabelecimento('codigoBeneficio', e.target.value.trim())}
                          />
                        </FormGroup>
                      </Col>
                    </Row>                   
                  </div>
                  <Row className="align-items-center">
                    <Col md="12">
                      {
                        form.saving? (
                            <Button className="col-md-12" color="primary">
                              <Spinner size="sm"/>
                            </Button>
                          ): (
                            <Button className="col-md-12" color="primary" type="submit">
                              Salvar informações
                            </Button>
                        )
                      }
                    </Col>
                  </Row>
        </Form>
      </Drawer.Body>
    </Drawer>
  )
}

export default EstabelecimentoDrawer;