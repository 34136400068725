import types from './types';

export function getEstabelecimento() {
  return { type: types.GET_ESTABELECIMENTO};
}
export function updateEstabelecimento(estabelecimento) {
  return { type: types.UPDATE_ESTABELECIMENTO, estabelecimento };
}
export function editarEstabelecimento() {
  return { type: types.EDITAR_ESTABELECIMENTO }
}
export function deletarEstabelecimento() {
  return { type: types.DELETAR_ESTABELECIMENTO }
}
export function allEstabelecimentos() {
  return { type: types.ALL_ESTABELECIMENTO };
}