import React from 'react';
import { Link } from 'react-router-dom';
import Logodoutor from '../../assets/img/logodrclube.png';
import { useSelector } from 'react-redux';

const Header = ({signed}) => {
  const { gerente } = useSelector((state) => state.gerente);
  if(!signed){
    return(
    <header> 
      <div className="header-area">
          <div className="main-header-area sticky">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-2">
                    <div className="logo">
                        <Link to="/">
                          <img 
                            src={Logodoutor}
                            style={{width: 140}}
                            alt="..."
                          />
                        </Link> 
                    </div>
                </div>
            </div>   
          </div>
        </div>
      </div>
    </header>
    )
  }else{
    return (
    <header className="container-fluid d-flex justify-content-end">
      <div className="d-flex align-items-center">
        <div className="text-right mr-3">
          <span className="d-block m-0 p-0 text-white">{gerente?.usuario}</span>
        </div>
      </div>
    </header>
    )
  }
}

export default Header;