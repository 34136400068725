import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


import Helmet from 'react-helmet'
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';

const DefaultLayout = ({children}) =>{
  const location = useLocation();
  return(
    <div className="container-fluid h-100">
      <Helmet title={`CRM Doutor Clube - ${
          location.pathname === '/estabelecimentos' && 'estabelecimentos',
          location.pathname === '/enderecos' && 'endereços'
        }`}
      />
      <div className="row h-100">
        <Header signed={true}/>
        <Sidebar />
        {children}
      </div>
    </div>
  )
}
export default DefaultLayout;