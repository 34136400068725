import { useState, useEffect } from 'react';
import { Button, Spinner} from 'reactstrap';
import { Drawer } from 'rsuite';


import { notification } from '../../services/rsuite';
import api from '../../services/api';

import './styles.css'

const Banners = () =>{
    const [form, setForm] = useState({});
    const [banners, setBanners] = useState([]);

    const onSubmit = async () =>{
      setForm({...form, saving: true})
      const { data } = await api.post('/banner', form);
      if (data.error) {
        notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data.message,
        });
      }
      setForm({})
    }

    const onRemove = async id =>{
      setForm({...form, saving: true})
      
      const { data } = await api.delete(`/banner/${id}`);
      
      if (data.error) {
          notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data.message,
          });
          return false
      }
      notification('success', {
          placement: 'topStart',
          title: 'Pronto',
          description: data.message,
      });
      setForm({})
    }

    const onUpdate = async () =>{
      setForm({...form, saving: true})
      
      const { data } = await api.put(`/banner/${form._id}`, {...form, _id: undefined, __v: undefined});

      
      if (data.error) {
          notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data.message?.name || data.message,
          });
          return false
      }
      notification('success', {
          placement: 'topStart',
          title: 'Pronto',
          description: data.message,
      });
      setForm({})
    }

    useEffect(() =>{
        const fetchData = async () =>{
            const { data } = await api.get(`/banner/lista`);    
            if (data.error) {
            notification('error', {
                placement: 'topStart',
                title: 'Ops...',
                description: data.message,
            });
            return false;
            }
            console.log(data)
            setBanners(data?.banners);
        }            
        fetchData();
    }, [form?.saving]);
    
  return(
    <>
    <Drawer
        show={form?.components?.create}
        size="sm"
        onHide={() => setForm({})}
    >
      <Drawer.Body>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Imagem (url)</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Imagem (url)"
            value={form?.imagem}
            onChange={(e) => {
              setForm({...form, imagem: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">link</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="link"
            value={form?.link}
            onChange={(e) => {
              setForm({...form, link: e.target.value});
            }}
          />
        </div>
        <div className="col-12">
          {
            form.saving? (
                <Button className="col-md-12" color="primary">
                  <Spinner size="sm"/>
                </Button>
            ):
            (
              <Button 
                className="col-md-12 m-2" 
                color="primary"
                type="button"
                onClick={() => onSubmit()}
              >
                Adicionar banner
              </Button>
            )
          }
        </div>
      </Drawer.Body>
      </Drawer>
      <Drawer
        show={form?.components?.update}
        size="sm"
        onHide={() => setForm({})}
    >
      <Drawer.Body>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Imagem(url)</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="Imagem(url)"
            value={form?.imagem}
            onChange={(e) => {
              setForm({...form, imagem: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">link</b>
          <input
            type="text"
            className="form-control form-control-alternative"
            placeholder="link"
            value={form?.link}
            onChange={(e) => {
              setForm({...form, link: e.target.value});
            }}
          />
        </div>
        <div className="col-12">
          {
            form.saving? (
                <Button className="col-md-12" color="primary">
                  <Spinner size="sm"/>
                </Button>
            ):
            (
              <Button 
                className="col-md-12 m-2" 
                color="primary"
                type="button"
                onClick={() => onUpdate()}
              >
                Atualizar banner
              </Button>
            )
          }
        </div>
      </Drawer.Body>
      </Drawer>
      <div className="col p-5 overflow-inherit banners" style={{minHeight: '100vh'}}>
        <div className="d-sm-flex align-items-center mb-4">
          <h2 className="mb-4 mt-4 text-muted mr-auto">Banners</h2>
          <div>
            <Button 
              className="" 
              color="primary" 
              type="button"
              onClick={() => setForm({components: {create: true}})}
            >
              Novo banner
            </Button>
          </div>
        </div>
      <div className="">
      { 
        banners
        ?.map(e => (
          <div className="row col-12 mt-1 border-bottom border-black item" key={e?.imagem}>
            <div className="column">
              <img 
                src={e?.imagem}
                className=""
                />
            </div>
            {
              form.saving? (
              <Button 
                color="info" 
                type="button"
                className="ml-3  mb-auto"
              >
                <Spinner size="sm"/>
              </Button>
            ):(
              <Button 
                color="info" 
                type="button"
                className="ml-3 mb-auto"
                onClick={() => setForm({...e, components: {update: true}})}
              >
                Editar
              </Button>
            )
          }
          {
            form.saving? (
            <Button 
              color="danger" 
              type="button"
              className="ml-3 mb-auto"
            >
              <Spinner size="sm"/>
            </Button>
          ):(
            <Button 
              color="danger" 
              type="button"
              className="mb-auto"
              onClick={() => onRemove(e?._id)}
            >
              Excluir
            </Button>
          )
        }
        </div>
      ))
    }
    </div>
  </div>
  </>
  )
}
export default Banners;