import React, { useState } from 'react';
import {
  Container,
  Button,
  Spinner,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, updateGerente } from '../../store/modules/gerente/actions';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const Login = () => {
  const schema = yup.object().shape({
    usuario: yup.string('insira um usuario válido!').required('insira um usuario!'),
    senha: yup.string('insira uma senha válida!').required('insira uma senha!').min(8, 'insira uma senha com 8 dígitos ou mais!'),
  })
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { form } = useSelector((state) => state.gerente);

  const setGerente = (key, value) => {
    dispatch(
      updateGerente({
        form: {...form, [key]: value }
      }
    )
    );
  };
  const { register, handleSubmit, formState: { errors }  } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = () =>{
    dispatch(login())
  }
  return (
    <Container className="mt--9 pb-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7 mx-auto h-100 mt-5">
          <Card className="bg-secondary shadow border-0 mt-5">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={form.saving? (e) => e.preventDefault() : handleSubmit(onSubmit)}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      placeholder="Usuário"
                      autoComplete="new-usuario"
                      type="password"
                      className="form-control"
                      ref={register}
                      {...register("usuario", {
                        onChange:(e) => setGerente('usuario', e.target.value.toLowerCase())
                      })}
                    />
                  </InputGroup>
                  {
                    errors.usuario?.type &&
                    <small className="validacao">{errors?.usuario?.message}</small>
                  }
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      placeholder="Senha"
                      type="password"
                      autoComplete="new-password"
                      className="form-control"
                      ref={register}
                      {...register('senha', {
                        onChange: (e) => setGerente('senha', e.target.value)
                      })}
                    />
                  </InputGroup>
                  {
                    errors.senha?.type &&
                    <small className="validacao">{errors?.senha?.message}</small>
                  }
                </FormGroup>
                <div className="text-center">
                  {
                    form.saving? (
                        <Button className="m3-4" color="primary">
                          <Spinner size="sm"/>
                        </Button>
                      ): (
                        <Button className="m3-4" color="primary" type="submit">
                          Entrar
                        </Button>
                      )
                  }
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;