import { 
    Input,
    Button,
    Spinner
  } from 'reactstrap';
  
  import { useState, useEffect } from 'react';
  
  import {
    Drawer,
  } from 'rsuite';

import { notification } from '../../services/rsuite';

import api from '../../services/api';

import './styles.css'
  
const Convenios = () =>{
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({});
  const [convenios, setConvenios] = useState([]);

 const onSubmit = async () =>{
  setForm({...form, saving: true})
  const { data } = await api.post('/convenio/', form);
  if (data.error) {
    notification('error', {
      placement: 'topStart',
      title: 'Ops...',
      description: data.message,
    });
  }
  setForm({})
  setShow(false)
}

const onRemove = async id =>{
  setForm({...form, saving: true})

  const { data } = await api.delete(`/convenio/${id}`);

  if (data.error) {
    notification('error', {
      placement: 'topStart',
      title: 'Ops...',
      description: data.message,
    });
    return false
  }
  notification('success', {
    placement: 'topStart',
    title: 'Pronto',
    description: data.message,
  });
  setForm({})
}

useEffect(() =>{
  const fetchConvenios = async () =>{
    const { data } = await api.get(`/convenio/lista`);    
    if (data.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: data.message,
      });
      return false;
    }                
    setConvenios(data?.convenios);
  }            
  fetchConvenios();
}, [form?.saving])
    
  return(
    <>
    <Drawer
        show={show}
        size="sm"
        onHide={() => setShow(false)}
    >
      <Drawer.Body>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Nome</b>
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Convênio"
            value={form?.nome}
            onChange={(e) => {
              setForm({...form, nome: e.target.value});
            }}
          />
        </div>
        <div className="form-group col-12">
          <b className="servico-label mb-2">Logo(url)</b>
          <input
            type="text"
            className="form-control"
            placeholder="Url da logo do Convênio"
            value={form?.logo}
            onChange={(e) => {
              setForm({...form, logo: e.target.value});
            }}
          />
        </div>
        <div className="col-12">
          {
            form.saving? (
                <Button className="col-md-12" color="primary">
                  <Spinner size="sm"/>
                </Button>
            ):
            (
              <Button 
                className="col-md-12 m-2" 
                color="primary"
                type="button"
                onClick={() => onSubmit()}
              >
                Adicionar Convênio
              </Button>
            )
          }
        </div>
      </Drawer.Body>
      </Drawer>
      <div className="col p-5 overflow-inherit convenios" style={{minHeight: '100vh'}}>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h2 className="mb-4 mt-4 text-muted">Convênios</h2>
          <div>
            <Button 
              className="" 
              color="primary" 
              type="button"
              onClick={() => setShow(true)}
            >
              Novo Convênio
            </Button>
          </div>
        </div>
      <div className="">
      { 
        convenios.map(e => (
          <div className="row col-12 mt-1 border-bottom border-black item" key={e?.nome}>
            <div className="column">
              <img 
                src={e.logo}
                className=""
                />
            </div>
            <div className="column">
              <h5 className="ml-2" style={{minWidth: 150}}>{e?.nome}</h5>
            </div>
              {
                form.saving? (
                <Button 
                  color="danger" 
                  type="button"
                >
                  <Spinner size="sm"/>
                </Button>
              ):(
                <Button 
                  color="danger" 
                  type="button"
                  onClick={() => onRemove(e?._id)}
                >
                  Excluir
                </Button>
              )
            }
          </div>
        ))
      }
    </div>
    </div>
    </>
  )
}
export default Convenios;