import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { isExpired } from "react-jwt";
import { useSelector, useDispatch } from 'react-redux';
import { getGerente, sair } from './store/modules/gerente/actions';

import './'

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

//import "./assets/css/owl.carousel.min.css"
import "./assets/css/magnific-popup.css"
import "./assets/css/font-awesome.min.css"
import "./assets/css/themify-icons.css"
import "./assets/css/nice-select.css"
//import "./assets/css/flaticon.css"
import "./assets/css/gijgo.css"
import "./assets/css/animate.min.css"
import "./assets/css/slick.css"
import "./assets/css/slicknav.css"
import "./assets/css/style.css"
import './style.css';


import AuthLayout from './layouts/Auth';
import DefaultLayout from './layouts/Default';
import Login from './pages/Login';
import Estabelecimentos from './pages/Estabelecimentos';
import Enderecos from './pages/Enderecos';
import Convenios from './pages/Convenios';
import Cupons from './pages/Cupons';
import Beneficio from './pages/Beneficio';
import Banners from './pages/Banners';



const ARoutes = () => {
  const { accessToken, gerente, form } = useSelector((state) => state.gerente);
  
  const dispatch = useDispatch();

  useEffect(() =>{
    if(!isExpired(accessToken) && accessToken){
      dispatch(getGerente())
    }else{
      dispatch(sair())
    }
   },[])
  if(isExpired(accessToken) || !accessToken){
    return (
      <Router>
        <AuthLayout>
          <Routes>
            <Route 
              path='*'
              element={<Navigate to="/login" />} 
            />
            <Route
              path="/login"
              exact
              element={<Login />}
            />
          </Routes>
        </AuthLayout>
      </Router>
    )
  }else{
    return (
      <Router>
        <DefaultLayout>
          <Routes>
            <Route 
              path='*'
              element={<Navigate to="/estabelecimentos" />} 
            />
            <Route
              path="/estabelecimentos"
              exact
              element={<Estabelecimentos />}
            />
               <Route
              path="/beneficio"
              exact
              element={<Beneficio />}
            />
            <Route
              path="/enderecos"
              exact
              element={<Enderecos />}
            />
             <Route
              path="/convenios"
              exact
              element={<Convenios />}
            />
            <Route
              path="/cupons"
              exact
              element={<Cupons />}
            />
            <Route
              path="/banners"
              exact
              element={<Banners />}
            />
          </Routes>
        </DefaultLayout>
      </Router>
    )
  }
};

export default ARoutes;