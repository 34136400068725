import { combineReducers } from 'redux';

import servico from './servico/reducer';
import gerente from './gerente/reducer';
import estabelecimento from './estabelecimento/reducer';

export default combineReducers({
  servico,
  gerente,
  estabelecimento
});
