import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DatePicker,
  Drawer,
  Uploader,
  Icon,
  Notification,
  Message,
} from 'rsuite';

import {
  Button,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Textarea,
  Container,
  Row,
  Col
} from "reactstrap";

import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from 'react-input-mask';


import {
  updateEstabelecimento,
  editarEstabelecimento
} from '../../store/modules/estabelecimento/actions';

import AdicionarConvenioDrawer from '../AdicionarConvenioDrawer';

import util from '../../services/util';
import api from '../../services/api';


const ConvenioDrawer = () =>{
  const { estabelecimento, components, form } = useSelector(
    state => state.estabelecimento
  )
  const dispatch = useDispatch();

  const setEstabelecimento = (key, value) => {
    dispatch(
      updateEstabelecimento({
        form: {...form, [key]: value }
      })
    );
  };

  const setComponents = (component, state) => {
    dispatch(
      updateEstabelecimento({
        components: { ...components, [component]: state }
      })
    );
  };
  const remove = value =>{
    value = JSON.parse(value || '')
    dispatch(
      updateEstabelecimento({
        form: { ...form, convenios: estabelecimento?.convenios?.filter((e) => e.nome !== value.nome)}
      })
    );
    dispatch(
      editarEstabelecimento()
    )
  }
  return(
    <>
    <AdicionarConvenioDrawer />
    <Drawer
        show={components?.convenios}
        size="sm"
        onHide={() => setComponents('convenios', false)}
    >
      <Drawer.Body>
        <h3>Convênios da {estabelecimento?.nomeEmpresarial}</h3>
        <div className="">
        { 
          estabelecimento?.convenios.map(e => (
            <div className="row col-12 mt-1 border-bottom border-black item" key={e?.nome}>
              <div className="column">
                <img 
                  src={e.logo}
                  className=""
                />
              </div>
              <div className="column">
                <h5 className="ml-2 mr-3">{e?.nome}</h5>
              </div>
                {
                  form.saving? (
                  <Button 
                    color="danger" 
                    type="button"
                  >
                    <Spinner size="sm"/>
                  </Button>
                ):(
                  <Button 
                    color="danger" 
                    type="button"
                    onClick={() => remove(JSON.stringify(e))}
                  >
                    Excluir
                  </Button>
                )
              }
            </div>
          ))
        }
        </div>
      </Drawer.Body>
    </Drawer>
    </>
  )
}

export default ConvenioDrawer;