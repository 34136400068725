import { useEffect, useState } from 'react';

import {
  Spinner,
  Button
} from "reactstrap";

import {
  Drawer,
} from 'rsuite';

import { notification } from '../../services/rsuite';

import api from '../../services/api';

export default () => {
  const [estados, setEstados] = useState([]);
  const [estado, setEstado] = useState(null);
  const [show, setShow] = useState();
  const [form, setForm] = useState({
    loading: false,
    sigla: '',
    cidade: {
      nome: ''
    },
    components: {
      newState: false,
      newCity: false,
      listCity: false
    }
  });

  const onAddState = async () =>{
    setForm({...form, loading: true})
    const { data } = await api.post('/estado/', form);

    if (data.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: data.message,
      });
      setForm({...form, loading: false})
      return false;
    }
    notification('success', {
      placement: 'topStart',
      title: 'Pronto!',
      description: "Estado adicionado",
    });
    setForm({});
  }


  useEffect(() =>{
    const fetch = async () =>{
      const { data } = await api.get('/estado/lista');
  
      if (data.error) {
        notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data.message,
        });
        return false;
      }
      setEstados(data.estados)
    }
    fetch();
  }, [form.loading])
  
  const fetchCitys = async () =>{
    const { data } = await api.get(`/cidade/lista/${estado?._id}`);    
    if (data.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: data.message,
      });
      return false;
    }
    
    setEstado({...estado, cidades: data.cidades});
  }

  useEffect(() =>{
    if(form?.components?.listCity){
      fetchCitys();
    }
  }, [form?.components?.listCity])

  const onAddCity = async () =>{
    setForm({...form, loading: true})
    const { data } = await api.post('/cidade/', {...form.cidade, estadoId: estado?._id, estado: estado?.sigla});

    if (data.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: data.message,
      });
      setForm({});
      return false;
    }
    notification('success', {
      placement: 'topStart',
      title: 'Pronto!',
      description: "Cidade adicionada",
    });
    setForm({});
  }
  return (
    <>
    <Drawer
        show={form?.components?.newCity}
        size="sm"
        onHide={() => setForm({...form, components: { ...form?.components, newCity: false }})}
    >
      <Drawer.Body>
        <>
          <div className="col-12 mb-3">
            <h3>Nova cidade do {estado?.sigla}</h3>
          </div>
          <div className="form-group col-12">
            <b className="servico-label mb-2">Nome</b>
            <input
              type="text"
              className="form-control"
              placeholder="Nome da cidade"
              value={form?.cidade?.nome}
              onChange={(e) => {
                setForm({...form, cidade: {...form?.cidade, nome: e.target.value}});
              }}
            />
          </div>
          <div className="col-12">
            {
              form?.loading? (
                  <Button className="col-md-12" color="primary">
                    <Spinner size="sm"/>
                  </Button>
              ):
              (
                <Button 
                  className="col-md-12" 
                  color="primary"
                  type="button"
                  onClick={() => onAddCity()}
                >
                  Adicionar Cidade
                </Button>
              )
            };
          </div>
        </>
      </Drawer.Body>
    </Drawer>
    <Drawer
        show={form?.components?.listCity}
        size="sm"
        onHide={() => setForm({...form, components: { ...form?.components, listCity: false }})}
    >
      <Drawer.Body>
          <>
            <div className="col-12 mb-3">
              <h3>Cidades do {estado?.sigla}</h3>
            </div>
            <div className="col-12">
              {
                estado?.cidades?.map(e =>(
                  <h5 className="py-2 border-bottom">
                    {e?.nome}
                  </h5>
                ))
              }
            </div>
          </>
      </Drawer.Body>
    </Drawer>
    <Drawer
        show={form?.components?.newState}
        size="sm"
        onHide={() => setForm({...form, components: { ...form?.components, newState: false }})}
    >
      <Drawer.Body>
          <>
            <div className="col-12 mb-3">
              <h3>Novo estado</h3>
            </div>
            <div className="form-group col-12">
              <b className="servico-label mb-2">Sigla</b>
              <input
                type="text"
                className="form-control"
                placeholder="Sigla do estado"
                value={form.titulo}
                onChange={(e) => {
                  setForm({...form, sigla: e.target.value});
                }}
              />
            </div>
            <div className="col-12">
              {
                form?.loading? (
                    <Button className="col-md-12" color="primary">
                      <Spinner size="sm"/>
                    </Button>
                ):
                (
                  <Button 
                    className="col-md-12" 
                    color="primary"
                    type="button"
                    onClick={() => onAddState()}
                  >
                    Adicionar Estado
                  </Button>
                )
              }
            </div>
          </>
      </Drawer.Body>
    </Drawer>
    <div className="col p-5 overflow-inherit" style={{minHeight: '100vh'}}>
      <div className="row align-items-start justify-content-between mb-5 mt-3">
        <h2 className="text-muted">Estados</h2>
        <div>
            <Button 
              className="" 
              color="primary" 
              type="button"
              onClick={() => setForm({...form, components: { ...form?.components, newState: true }})}
            >
              Novo estado
            </Button>
          </div>
      </div>
      <div>
        {
          estados?.map(e =>(
            <div className="border-bottom py-3 px-2 d-flex align-items-center">
              <h3 className="h2 pl-auto text-dark" style={{width: 100}}>{e?.sigla}</h3>
              <div className="pr-2">
                <a 
                  className="col-md-12 m-2" 
                  color="info"
                  onClick={() => {
                    setEstado(e)
                    setForm({...form, components: { ...form?.components, listCity: true }})}
                  }
                >
                  Ver Cidades
                </a>
              </div>
              <div>
                <a 
                  className="col-md-12 m-2" 
                  color="primary"
                  type="button"
                  onClick={() => {
                    setEstado(e)
                    setForm({...form, components: { ...form?.components, newCity: true }})}
                  }
                >
                  Adicionar Cidade
                </a>
              </div>
            </div>
          ))
        }
      </div>
    </div>
    </>
  );
};